<template>
  <div class="flex space-x-2 items-center">
    <font-awesome-icon
      :class="iconClassname"
      icon="fa-solid fa-circle-info"
    />
    <slot name="help" />
    <span
      v-if="!!help"
      :class="textClassname"
    >
      {{ help }}
    </span>
  </div>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default {
  name: 'HelpInfo',
  components: {FontAwesomeIcon},
  props: {
    help: {
      type: String
    },
    textClassname: {
      type: String,
      default: 'text-space-dark-blue'
    },
    iconClassname: {
      type: String,
      default: 'text-space-dark-blue'
    }
  }
};
</script>
