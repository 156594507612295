import i18n from "@/i18n/i18n";
import {getLanguageIcon} from "@/constants/languages/LanguagesConstants";

export const EXERCISE_TABLE_HEADER_NAME = {
  name: i18n.t('exercises.name'),
  value: 'name',
};

export const EXERCISE_TABLE_HEADER_LANGUAGE = {
  name: i18n.t('exercises.language'),
  value: 'language',
};

export const EXERCISE_TABLE_HEADER_ACCESS = {
  name: i18n.t('exercises.access'),
  value: 'access',
};

export const EXERCISE_TABLE_HEADER_CREATOR = {
  name: i18n.t('exercises.creator'),
  value: 'creator',
};

export const EXERCISE_TABLE_HEADER_CREATED_AT = {
  name: i18n.t('exercises.created_at'),
  value: 'createdAt',
};

export const EXERCISE_TABLE_HEADER_ACTIONS = {
  name: i18n.t('common.table.actions'),
  value: 'actions'
};
export const EXERCISES_TABLE_HEADER = (isCommunity) => [
  EXERCISE_TABLE_HEADER_NAME,
  EXERCISE_TABLE_HEADER_LANGUAGE,
  EXERCISE_TABLE_HEADER_CREATOR,
  EXERCISE_TABLE_HEADER_CREATED_AT,
  ...(isCommunity ? [] : [
    EXERCISE_TABLE_HEADER_ACCESS,
  ]),
  {
    ...EXERCISE_TABLE_HEADER_ACTIONS,
    classname: `actions ${isCommunity ? 'w-1/12' : 'w-1/10'}`,
  }
];

export const EXERCISES_BUNDLES_TABS = [
  {
    name: i18n.t('exercises.tabs.bundles'),
    value: 'bundles',
    active: true
  },
  {
    name: i18n.t('exercises.tabs.exercises'),
    value: 'exercises',
  }
];

export const FILTER_OPTION_ALL = {
  name: i18n.t('filters.options.all'),
  value: 'all'
};

export const EXERCISE_TYPE_ERROR = {
  name: i18n.t('exercises.types.error'),
  value: 'error'
};

export const EXERCISES_FILTERS = [
  {
    name: i18n.t('exercises.filters.languages'),
    field: 'language',
    isArray: true,
    operator: 'in',
    options: [
      {
        name: 'javascript',
        icon: getLanguageIcon('javascript'),
        value: 'javascript',
      },
      {
        name: 'PHP',
        icon: getLanguageIcon('php'),
        value: 'php',
      },
      {
        name: 'Python',
        icon: getLanguageIcon('python'),
        value: 'python',
      },
    ]
  },
  {
    isArray: true,
    operator: 'in',
    name: i18n.t('exercises.filters.types'),
    field: 'type',
    options: [
      EXERCISE_TYPE_ERROR
    ]
  }
];


