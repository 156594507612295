<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    :classes="`${classes} modal-container`"
    :content-class="contentClass"
    v-on="$listeners"
  >
    <div class="modal__title">
      <div class="modal__title__text">
        <slot name="title"/>
      </div>
      <div class="modal__title__icon">
        <font-awesome-icon
          v-if="showCloseIcon"
          icon="fa-solid fa-circle-xmark"
          class="justify-self-end text-space-dark-blue h-8 w-8 cursor-pointer"
          @click="() => handleClose(close)"
        />
      </div>
    </div>
    <div class="modal__content">
      <slot :params="params"/>
      <slot
        name="content"
        :close="close"
      />
    </div>
    <span class="modal__action">
      <slot name="action"/>
    </span>
  </vue-final-modal>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "Modal",
  components: {FontAwesomeIcon},
  inheritAttrs: false,
  props: {
    onClose: {
      type: Function,
      default: () => {
      },
    },
    contentClass: {
      type: String,
      default: "modal-content relative justify-center bg-transparent w-1/2 overflow-y-auto"
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String
    }
  },
  methods: {
    handleClose(close) {
      this.onClose && this.onClose();
      close();
    }
  }
};
</script>
<style>
@import 'modal.css';
</style>
